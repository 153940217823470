// src/contexts/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  doc,
  setDoc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  collection,
  getDocs,
  query,
  where,
  getDoc
} from 'firebase/firestore';
import { toast } from 'react-toastify';

export const UserContext = createContext();

/** 
 * Derives the default level from a grade, e.g. "grade_4" => "level_4_1"
 */
function deriveDefaultLevel(grade) {
  const parts = grade.split('_'); // e.g. ["grade", "4"]
  if (parts.length === 2) {
    return `level_${parts[1]}_1`; // "level_4_1" if grade_4
  }
  return 'level_1_1'; // fallback
}

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading]   = useState(true);
  const [error, setError]       = useState(null);

  useEffect(() => {
    let unsubscribeUser = () => {};

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid);

          unsubscribeUser = onSnapshot(
            userRef,
            async (docSnap) => {
              if (!docSnap.exists()) {
                // Create an initial user doc if none exists
                console.log('[UserContext] No user doc => creating initial doc');
                const initialData = {
                  email: user.email,
                  gradeLevel: 'grade_1',
                  avatar: '',
                  currentLevel: 'level_1_1',
                  currentFocusArea: 'focusArea_focusedBreathing',
                  totalMindfulnessMinutes: 0,
                  completedSkillsByGrade: {},
                  achievements: [],
                  earnedBadges: [],
                  skillCompletionHistory: [],
                  streak: 0,
                  streakHistory: [],
                  theme: 'light',
                  hasCompletedOnboarding: false,
                  accountType: 'individual',
                  classes: [],
                  currentClassId: '',
                  createdAt: new Date().toISOString(),
                };
                try {
                  await setDoc(userRef, initialData, { merge: true });
                  console.log('[UserContext] Created user doc successfully');
                  setUserData({
                    uid: user.uid,
                    email: user.email,
                    accountType: 'individual',
                    ...initialData,
                  });
                } catch (createErr) {
                  console.error('[UserContext] Failed to create user doc =>', createErr);
                  setError('Failed to create initial user doc. Please check permissions.');
                }
                setLoading(false);
                return;
              }

              // docSnap exists
              const data = docSnap.data() || {};
              let derivedGrade = data.gradeLevel || 'grade_1';
              let currentLevelId = data.currentLevel;

              // if user has no currentLevel => derive from grade
              if (!currentLevelId) {
                currentLevelId = deriveDefaultLevel(derivedGrade);
                console.log('[UserContext] Assigning default currentLevel =>', currentLevelId);
                try {
                  await updateDoc(userRef, { currentLevel: currentLevelId });
                  console.log('[UserContext] Updated user doc with currentLevel =>', currentLevelId);
                } catch (err) {
                  console.error('[UserContext] Failed to update user currentLevel =>', err);
                }
              }

              // if user has no classes or no currentClassId => create default
              let userClasses = data.classes || [];
              let currentClassId = data.currentClassId || '';

              if (!userClasses.length) {
                console.log('[UserContext] No classes => creating default class');
                const defaultClassId = 'class_default';
                userClasses = [{
                  classId: defaultClassId,
                  className: 'My Class',
                  gradeLevel: derivedGrade,
                  currentLevel: currentLevelId || deriveDefaultLevel(derivedGrade),
                  createdAt: new Date().toISOString()
                }];

                currentClassId = defaultClassId;
                try {
                  await updateDoc(userRef, {
                    classes: userClasses,
                    currentClassId
                  });
                  console.log('[UserContext] Updated user doc with default class');
                } catch (clErr) {
                  console.error('[UserContext] Failed to update user doc with classes =>', clErr);
                }
              } else if (!currentClassId) {
                // If classes exist but no currentClassId => pick the first
                currentClassId = userClasses[0].classId;
                console.log('[UserContext] Setting currentClassId =>', currentClassId);
                try {
                  await updateDoc(userRef, { currentClassId });
                  console.log('[UserContext] Updated user doc with currentClassId =>', currentClassId);
                } catch (cidErr) {
                  console.error('[UserContext] Failed to set currentClassId =>', cidErr);
                }
              }

              // Now create userPlans doc if it doesn't exist
              const planDocId = `${user.uid}_${currentClassId}`;
              console.log('[UserContext] Checking userPlans =>', planDocId);
              const planRef = doc(db, 'userPlans', planDocId);
              try {
                const planSnap = await getDoc(planRef);
                if (!planSnap.exists()) {
                  console.log('[UserContext] userPlans doc does NOT exist => creating');
                  await setDoc(planRef, {
                    userId: user.uid, // must match for security rules
                    focusAreaOrder: [],
                    createdAt: new Date().toISOString()
                  });
                  console.log('[UserContext] Created userPlans doc =>', planDocId);
                } else {
                  console.log('[UserContext] userPlans doc already exists =>', planDocId);
                }
              } catch (planErr) {
                console.error('[UserContext] Failed to create userPlans doc =>', planErr);
              }

              // Done => set local userData
              const accountType = data.accountType || 'individual';
              setUserData({
                uid: user.uid,
                email: user.email,
                accountType,
                ...data,
                currentLevel: currentLevelId
              });
              setLoading(false);
            },
            (error) => {
              console.error('[UserContext] onSnapshot error =>', error);
              setError('Failed to load user data. Please try again later.');
              setLoading(false);
            }
          );
        } catch (error) {
          console.error('[UserContext] Outer try =>', error);
          setError('Failed to load user data. Please try again later.');
          setUserData(null);
          setLoading(false);
        }
      } else {
        // user logged out
        setUserData(null);
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
      unsubscribeUser();
    };
  }, []);

  /**
   * Example function for skill completion
   */
  const markSkillAsComplete = async (
    skillId,
    gradeLevel,
    levelId,
    focusAreaId,
    skillDuration = 5
  ) => {
    if (!userData) {
      toast.error('User not logged in.');
      return;
    }
    try {
      const userRef = doc(db, 'users', userData.uid);
      const path = `completedSkillsByGrade.${gradeLevel}.${levelId}.${focusAreaId}`;

      console.log('[UserContext] Marking skill complete =>', path, skillId);
      await updateDoc(userRef, {
        [path]: arrayUnion(skillId),
        totalMindfulnessMinutes: (userData.totalMindfulnessMinutes || 0) + skillDuration,
        skillCompletionHistory: arrayUnion({
          date: new Date().toISOString().split('T')[0],
          skillsCompleted: 1,
          timestamp: Date.now(),
          skillId,
        }),
      });
      console.log('[UserContext] Skill marked complete =>', skillId);
      toast.success('Skill marked as complete!');
    } catch (err) {
      console.error('[UserContext] Error marking skill as complete =>', err);
      toast.error('Failed to mark skill as complete. Check console logs for details.');
    }
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        loading,
        error,
        markSkillAsComplete,
        setUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
